import { graphql } from "gatsby"
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"
import React from "react"
import Tag from "../components/common/Tag"

import TagsTree from "../components/TagsTree"
import InstagramIcon from "../components/icons/InstagramIcon"
import LinkIcon from "../components/icons/LinkIcon"
import InstagramImages from "../components/sections/InstagramImages"
import Progress from "../components/Progress"
import {
  MOBILE_X_PADDING,
  PINK_LIGHT_BG,
  YELLOW_BG,
} from "../gatsby-theme-material-ui-top-layout/theme"
import SectionContainer from "../components/layout/SectionContainer"
import BrandCardList from "../components/common/BrandCardList/BrandCardList"
import TitleAndParagraph from "../components/simple/TitleAndParagraph"
import Image from "../components/simple/Image"
import GridSection from "../components/sections/GridSection"
import DonateSection from "../components/sections/DonateSection"
import Layout from "../components/layout/Layout"
import clsx from "clsx"
import TransparentRouterLink from "../components/common/TransparentRouterLink"
import { CurrencyContext, useCurrencies, useMobile } from "../utils/hooks"
import GatsbyImage from "../components/common/GatsbyImage"

const useStyles = makeStyles(theme => ({
  uppercase: {
    textTransform: "uppercase",
  },
}))

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const classes = useStyles()
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, fields } = markdownRemark
  const currencyValue = useCurrencies(frontmatter.currency)
  const mobile = useMobile()

  return (
    <CurrencyContext.Provider value={currencyValue}>
      <Layout
        {...{
          [mobile ? "navbarLeftSlot" : "navbarRightSlot"]: (
            <Typography
              component={TransparentRouterLink}
              to={fields.url}
              variant="h6"
            >
              Cofnij
            </Typography>
          ),
        }}
      >
        <Grid container>
          <Grid item xs={12} md={3} container direction="column">
            <GatsbyImage
              filename={frontmatter.cardImage}
              style={{ maxHeight: 440 }}
              objectFit="cover"
              objectPosition="top"
            />

            <Box bgcolor={PINK_LIGHT_BG} p={mobile ? MOBILE_X_PADDING : 6}>
              <Typography variant="h5" className={classes.uppercase}>
                {frontmatter.donateOwnerInfo.name}
              </Typography>

              <Box mt={4} />

              <Typography>{frontmatter.donateOwnerInfo.description}</Typography>
            </Box>

            <GatsbyImage
              style={{ maxHeight: 440 }}
              filename={frontmatter.donateImage2?.image}
              alt={frontmatter.donateImage2?.alt}
              objectFit="cover"
              objectPosition={frontmatter.donateImage2?.position}
            />
          </Grid>

          <Grid item xs={12} md={9} container alignItems="flex-start">
            <Grid item xs={12} container>
              <Box
                bgcolor={frontmatter.brandColor}
                px={mobile ? MOBILE_X_PADDING : 12}
                py={mobile ? MOBILE_X_PADDING : 6}
                position="relative"
                zIndex={4}
                width="100%"
              >
                <Typography
                  component="h1"
                  variant={mobile ? "h2" : "h1"}
                  style={{ color: frontmatter.textColor }}
                  className={classes.uppercase}
                >
                  {frontmatter.name}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={8} container direction="column">
              <BenefitsSection items={frontmatter.donateOptions} />
            </Grid>

            <Grid item xs={12} md={4}>
              <GatsbyImage
                filename={frontmatter.donateImage3?.image}
                alt={frontmatter.donateImage3?.alt}
                objectFit="cover"
                objectPosition={frontmatter.donateImage3?.position}
                style={
                  mobile
                    ? {}
                    : {
                        position: "relative",
                        marginBottom: -48,
                        height: `calc( 100% + 48px )`,
                      }
                }
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "auto", marginBottom: 0 }}>
              <Box
                px={mobile ? MOBILE_X_PADDING : 12}
                py={8}
                pr={mobile ? MOBILE_X_PADDING : "40%"}
                bgcolor={YELLOW_BG}
              >
                <Typography variant="h5" className={classes.uppercase}>
                  Witaj DOMINIKA
                </Typography>

                <Box mt={4} />

                <Typography>
                  Dziękuje że decydujesz się wesprzeć mój projekt! Pamiętaj że
                  dokonanie wpłaty jest równoznaczne z podpisaniem umowy smart
                  contract. Pamiętaj, że zwrot z inwestycji nie jest
                  gwarantowany.
                </Typography>

                <Box mt={4} />

                <Button variant="contained" color="primary" size="large">
                  Wesprzyj projekt
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </CurrencyContext.Provider>
  )
}

export const pageQuery = graphql`
  query($slug: String) {
    markdownRemark(
      fields: { collection: { eq: "brands" }, slug: { eq: $slug } }
    ) {
      id
      frontmatter {
        brandColor
        textColor
        image
        cardImage
        brandColor
        currency
        name
        donateProgress
        donateProgressSteps {
          title
          description
          value
        }
        donateOptions {
          sharesPromil
          contribution
          benefits
        }
        donateOwnerInfo {
          name
          description
          image
        }
        donateImage2 {
          image
          position
        }
        donateImage3 {
          image
          position
        }
      }
      fields {
        url
      }
    }
  }
`

const useStyles2 = makeStyles(theme => ({
  uppercase: {
    textTransform: "uppercase",
  },
  tagWrapper: {
    "&:hover": {
      opacity: 0.6,
    },
    opacity: 0.4,
    cursor: "pointer",
  },
  tagActiveWrapper: {
    opacity: 1,
    "&:hover": {
      opacity: 1,
    },
  },
}))

const BenefitsSection = props => {
  const { items } = props
  const mobile = useMobile()
  const classes = useStyles2()
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const { label: currencyLabel } = React.useContext(CurrencyContext)

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        px={mobile ? MOBILE_X_PADDING : 12}
        position="relative"
        {...(mobile
          ? { top: "-48px" }
          : { top: "-140px", mb: "-140px", left: "-16px" })}
      >
        {items.map((x, index) => (
          <Box
            flexGrow={1}
            className={clsx(classes.tagWrapper, {
              [classes.tagActiveWrapper]: index === selectedIndex,
            })}
            onClick={() => setSelectedIndex(index)}
          >
            <Tag variant="square">
              <Box display="flex" flexDirection="column" height="100%">
                <Box flexGrow={1} />
                <Typography variant="h4" gutterBottom>
                  {x.sharesPromil}‰
                </Typography>
                <Box flexGrow={1} />
                <Typography gutterBottom>
                  {x.contribution} {currencyLabel}
                </Typography>
              </Box>
            </Tag>
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        px={mobile ? MOBILE_X_PADDING : 12}
        py={6}
      >
        <Typography variant="h5" className={classes.uppercase}>
          Benefity opcji
        </Typography>

        <Box mt={3} />

        <Typography>
          <Box fontWeight={600}>
            Wybierasz profit {items[selectedIndex].sharesPromil}‰, wspierając
            mój projekt kwotą {items[selectedIndex].label}.
          </Box>
        </Typography>

        <Box component="ul" mt={1}>
          {items[selectedIndex].benefits.map(x => (
            <li key={x}>{x}</li>
          ))}
        </Box>
      </Box>
    </>
  )
}
